.main {
  background-color: #1a1a1a;
  background: rgb(26,26,26);
  background: linear-gradient(90deg, rgba(26,26,26,1) 01%, rgba(17,17,17,1) 100%);  
  min-height: 100vh;
}

.content {
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding:20px;
  min-height: 100vh;
}

.header {
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  margin-bottom:20px;
}

.mainHeader {
  color: lightgray;
  font-size:40px;
  font-weight:600;
}

.secondaryHeader {
  color:darkgray;
  font-size:20px;
  font-weight:600;
}

.inputContainer {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:20px;
}

.emailInput {
  border-radius: 12px;
  border: none;
  font-size:18px;
  padding:10px;
  font-weight: 500;
  min-width: 20vw;
  outline:none;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(208,208,208,1) 100%);
  transition: all 200ms ease-in-out;
}

.emailInput:hover, .emailInput:focus {
  box-shadow:0px 0px 10px 0px gray;
}

.logo {
  max-width: 100px;
}

.joinButton {
  outline:none;
  border:none;
  border-radius:12px;
  color:white;
  background: rgb(43,43,43);
  background: linear-gradient(90deg, rgba(43,43,43,1) 0%, rgba(112,112,112,1) 100%);  
  padding:15px;
  font-size:15px;
  font-weight:600;
  max-width:100px;
  min-width:80px;
  max-height:50px;
  cursor: pointer;
  transition: all 200ms ease-in-out;

}

.joinButton:hover, .joinButton:active {
  box-shadow:0px 0px 10px 0px gray;
}

.wrapper {
  height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:#eee
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #00ffcd;
  fill: none;
}

.checkmark__circle.active {
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 3;
  stroke: black;
  stroke-miterlimit: 10;
  /* margin: 10% auto; */
  margin:20px;
  box-shadow: inset 0px 0px 0px #00ffcd;
}

.checkmark.active {
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
}

.checkmark__check.active {
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}

@keyframes stroke {
  100%{stroke-dashoffset: 0}
}
@keyframes scale {
  0%, 100%{transform: none}50%{transform: scale3d(1.1, 1.1, 1)}
} 
@keyframes fill {
  100%{box-shadow: inset 0px 0px 0px 30px #00ffcd}
}

.error {
  color:rgb(129, 0, 0);
  font-size:20px;
  font-weight:600;
  text-align: center;
  margin-top:10px;
}

/* Loading */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}